// app/javascript/controllers/inline_edit_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["display", "input", "form", "toggle"];

  connect() {
    this.editing = false; // Track editing state
  }

  enterEdit() {
    this.editing = true;
    if (this.hasInputTarget) {
      this.displayTarget.classList.add("hidden");
      this.inputTarget.classList.remove("hidden");
      this.inputTarget.focus();
    }
  }

  exitEdit(event) {
    if (event.type === "blur" || (event.type === "keyup" && event.key === "Enter")) {
      this.submitEdit();
    }
  }

  submitEdit() {
    if (this.hasInputTarget) {
      const value = this.inputTarget.value;
      const url = this.formTarget.action;
      const csrfToken = document.querySelector("[name='csrf-token']").content;

      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ value }),
      }).then(() => {
        this.displayTarget.textContent = value;
        this.inputTarget.classList.add("hidden");
        this.displayTarget.classList.remove("hidden");
        this.editing = false;
      });
    }
  }

  toggleCompleted() {
    const currentValue = this.displayTarget.textContent.trim() === "Yes";
    const newValue = !currentValue;

    const url = this.formTarget.action;
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ completed: newValue }),
    }).then(() => {
      this.displayTarget.textContent = newValue ? "Yes" : "No";
    });
  }
}
