import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-gesture-notification"
export default class extends Controller {
  connect() {
    console.log("UserGestureNotification controller connected.");
    this.registerServiceWorker(); // Ensure the service worker is registered
  }

  async requestPermission() {
    try {
      // Request notification permissions
      const status = await Notification.requestPermission();
      console.log(`Notification permission result: ${status}`);
      console.log(`Current Notification.permission: ${Notification.permission}`);

      if (status === "granted") {
        console.log("Notifications are enabled.");
        await this.registerDevice(); // Register the device after permissions are granted
      } else if (status === "denied") {
        console.warn("Notifications were denied or dismissed.");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  }

  async registerServiceWorker() {
    if (navigator.serviceWorker) {
      try {
        // Register the service worker
        await navigator.serviceWorker.register('/service-worker.js');
        console.log("Service worker registered successfully!");
      } catch (error) {
        console.error("Error registering service worker: ", error);
      }
    } else {
      console.error("Service workers are not supported in this browser.");
    }
  }

  async registerDevice() {
    try {
      if (!('serviceWorker' in navigator)) {
        console.error("Service workers are not supported in this browser.");
        return;
      }

      const registration = await navigator.serviceWorker.ready;
      console.log("Service worker is ready:", registration);

      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        // Subscribe the user for push notifications
        const vapidPublicKey = document.querySelector('meta[name="vapid-public-key"]').content;
        const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey);

        const newSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        });

        // Send subscription data to the server
        await this.sendSubscriptionToServer(newSubscription);
      } else {
        console.log("User is already subscribed:", subscription);
      }
    } catch (error) {
      console.error("Error registering device: ", error);
    }
  }

  async sendSubscriptionToServer(subscription) {
    try {
      const {
        endpoint,
        keys: { p256dh, auth },
      } = subscription.toJSON();
  
      const body = JSON.stringify({
        subscription: { endpoint, p256dh_key: p256dh, auth_key: auth },
      });
  
      const csrfToken = this.getCsrfToken();
  
      const response = await fetch("/subscriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken, // Include CSRF token
        },
        body,
      });
  
      if (response.ok) {
        console.log("Subscription sent to server successfully!");
      } else {
        console.error("Failed to send subscription to the server:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending subscription to the server: ", error);
    }
  }
  

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }
  
}


